
.loginBlock{
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
}

label{
    font-weight: bold;
}

.leftPanel{
    position: fixed;
    left: 0;
    right: 500px;
    top: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}


.leftPanel{
    background-color: #235680;
}

.leftPanel h4{
    font-family: 'Merriweather';
    font-size: 2rem;
    color: #fff !important;
}



.footer-content{
    position: absolute;
    bottom: 0;
    left: 0;
    background: #00000050;
    width: 100%;
    padding: 10px;
    text-align: left;
}
.footer-brand-2{
    height: 38px !important;
    margin-top: 35px;
}
.footer-brand{
    height: 100px;
    width: 100px;
}
.footer-text{
    margin-top: 35px;
    margin-left: 15px;
    display: inline-block;
    vertical-align: top;
    color: #fff;
}
.left-panel-img{
    width: 38%;
    height: auto;
    margin-top: 5%;
}
.app-title{
    font-family: 'Merriweather';
    font-size: 1.5rem;
    color: #183B58 !important;
    text-align: center;
    margin-top: 22% !important;
}
.app-logo{
    height: 80px;
}

.login {
    margin: -168px 0 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
}

.loginCustom{
    text-align: center;
}

.kiosk-logo-blue{
    height:12vh;
    margin-top: 10%;
}

.login.login-with-news-feed{
    background-color: #c7d3d8;
}

.login.login-with-news-feed .news-feed{
    border-right: 1px solid rgb(238, 238, 238);  
    background-color: #3998db;
}

.login.login-with-news-feed, .register.register-with-news-feed {
    width: 100%;
    margin: 0;
    padding: 0;
    top: 0;
}
.login.login-with-news-feed .news-feed, .register.register-with-news-feed .news-feed{
    position: fixed;
    left: 0;
    right: 500px;
    top: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}
.right-content {
    overflow: auto;
    overflow-x: hidden;
    padding: 10px 30px;
}
.login.login-with-news-feed .right-content, .register.register-with-news-feed .right-content {
    float: right;
    width: 500px;
    background-color: #c7d3d8;
}
.leftBottomBg{
    color: rgba(255,255,255,.75);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    font-size: 18px;
    z-index: 20;
    font-weight: 300;
    /* background: #122c51; */
    background:rgb(35, 86, 128);
    color: #ffffff;
}

.dta-footer-text{
    text-align: center;
}

.dta-footer-text-2{
    font-size: 13px;
}

.dta-iot-logo{
    text-align: right;
}
.footerSmSize{
    display: none;
}
.footer-text-3{
    text-align: center;
}

.footer-text-3-year{
    font-size: 13px;
}

@media (min-width: 1280px) and (max-width: 1366px) {

    .left-panel-img {
        width: 45%;
        height: auto;
        margin-top: 15%;
    }
}
@media (min-width: 1020px) and (max-width: 1280px) {
    .left-panel-img {
        width: 400px;
        height: auto;
        margin-top: 10%;
    }
    .footer-brand-2{
        height: 29px !important;
        margin-top: 52px;
        margin-left: -10px;
    }
    .footer-brand{
        margin-top: 15px;
        margin-left: 15px;
    }
}
@media (min-width: 940px) and (max-width: 1019px) {

    .left-panel-img {
        width: 80%;
        height: auto;
        margin-top: 25%;
    }
    .footer-brand-2 {
        height: 28px !important;
        margin-top: 56px;
        margin-left: -23px !important;
    }
    .footer-brand{
        margin-top: 15px;
        margin-left: 0;
    }
}
@media (min-width: 870px) and (max-width: 939px) {

    .left-panel-img {
        width: 80%;
        height: auto;
        margin-top: 25%;
    }
    .footer-brand-2 {
        height: 25px !important;
        margin-top: 55px;
        margin-left: -15px;
    }
    .footer-brand{
        margin-top: 15px;
        margin-left: 5px;
    }
    .login.login-with-news-feed .news-feed, .register.register-with-news-feed .news-feed{
        right: 50%;
    }
    .login.login-with-news-feed .right-content, .register.register-with-news-feed .right-content{
        width: 50%;
    }
}
@media (min-width: 769px) and (max-width: 869px) {

    .left-panel-img {
        width: 75%;
        height: auto;
        margin-top: 23%;
    }
    .footer-brand-2 {
        height: 35px !important;
        margin-top: 10px;
        margin-left: -15px;
    }
    .footer-brand{
        margin-top: 15px;
        margin-left: 5px;
    }
    .login.login-with-news-feed .news-feed, .register.register-with-news-feed .news-feed{
        right: 50%;
    }
    .login.login-with-news-feed .right-content, .register.register-with-news-feed .right-content{
        width: 50%;
    }
    .f-b-1,.f-b-2{
        text-align: center !important;
    }
    .footer-text{
        margin-top: 18px;
        margin-left: 0;
    }
    #footerMdSize{
        display: none;
    }
    #footerSmSize{
        display: block !important;
    }
}
@media (max-width: 768px){

    .login.login-with-news-feed .right-content, .register.register-with-news-feed .right-content {
        float: none;
        width: auto;
        padding: 15px;
    }
    .login.login-with-news-feed .news-feed, .register.register-with-news-feed .news-feed{
       display: none;
    }
}


.right-content.rightPanel{
    background:#fff !important;
}

.button-info{
    border-radius: 0.3rem !important;
    background-color: #005486 !important;
    border-color: #005486 !important;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    color: #fff;
    display: block;
    width: 100%;
}

.mb15{
    margin-bottom: 1.5rem !important;
}

.form-label{
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.form-group{
    font-size: 13px;
}

.form-control{
    display: block;
    font-size:13px;
    width: 100%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control.form-control-lg{
    padding: 0.5rem 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    font-size:13px;
}

.field-icon {
    float: right;
    margin-right: 15px;
    margin-top: -29px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}